<template lang="pug">
  div.wrap-module-select-meeting-room-type
    div.select-meeting-room-type-content
      span.label.fz14 ビデオ会議URL
      v-radio-group(v-model="meetingURLType")
        v-radio(
          v-for="(URLType, index) in meetingURLTypes"
          :key="index"
          :label="URLType" :value="URLType")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-select-meeting-room-type {
  width: 100%;
  // min-height: 100vh;
  // padding-top: $header_height;
  .select-meeting-room-type-content {
    padding-top: 24px;
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .label {
      color: $secondary_text_color;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const teamStore = createNamespacedHelpers('team')
import database from '@/database'

export default {
  computed: {
    ...mapStateAuth(['uid']),
    ...teamStore.mapState(['team']),
    meetingURLTypes () {
      if (this.team) {
        // return ['Google Meet', 'Zoom', 'Microsoft Teams', 'なし']
        let result = []
        if (this.googleToken) {
          result.push('Google Meet')
        }
        if (this.zoomToken) {
          result.push('Zoom')
        }
        if (this.microsoftToken) {
          result.push('Microsoft Teams')
        }
        result.push('なし')
        return result
      } else {
        return ['Google Meet', 'なし']
      }
    }
  },
  data() {
    return {
      meetingURLType: 'Google Meet',
      googleToken: null,
      zoomToken: null,
      microsoftToken: null
    }
  },
  async created () {
    this.googleToken = await database.userCollection()
      .getGoogleToken(this.uid)
    this.zoomToken = await database.userCollection()
      .getZoomToken(this.uid)
    this.microsoftToken = await database.userCollection()
      .getMicrosoftToken(this.uid)
  }
}
</script>
