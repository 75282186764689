<template lang="pug">
  div.wrap-module-set-group-to-meeting
    div(v-if="groups[0]").set-group-to-meeting-content
      ItemGroupCard(v-for="group in groups" :group="group"
        @setMemberByGroup="setMemberByGroup")
    div(v-else).no-group.f.fh
      span.fz14 グループがありません
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-set-group-to-meeting {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .set-group-to-meeting-content {
    // width: $base_width_percent;
    // max-width: $base_max_width_px;
    // margin: 0 auto;
  }
  .no-group {
    min-height: calc(100vh - #{$header_height});
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
import ItemGroupCard from '@/components/item/ItemGroupCard'

export default {
  components: {
    ItemGroupCard
  },
  props: {
    groups: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      
    }
  },
  methods: {
    setMemberByGroup (member) {
      this.$emit('setMemberByGroup', member)
    }
  }
}
</script>
