<template lang="pug">
  div(:class="{'invalid': isInvalidRange}").module-time-picker
    div.f.fm.mb4
      span.label {{ label }}
    div.wrap-time-selector.f.fm
      VueTimepicker(v-model="startTime"
        format="HH:mm"
        hour-label="時"
        minute-label="分"
        minute-interval="15"
        manual-input auto-scroll)
      span.px3 ~
      VueTimepicker(v-model="endTime"
        format="HH:mm"
        hour-label="時"
        minute-label="分"
        minute-interval="15"
        manual-input auto-scroll)

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.module-time-picker {
  border-bottom: solid 0.6px #777;
  .label {
    font-size: 12px;
    color: #777;
  }
  .wrap-time-selector {
    display: inline-flex;
    align-items: center;
    select {
      outline: none;
      font-size: 16px;
    }
  }
  &.invalid {
    span,
    select,
    option {
      color: $dangerous_color;
    }
    border-bottom: solid $dangerous_color 0.8px;
  }
}
</style>

<style lang="scss">
@import '@/scss/_variables.scss';

.module-time-picker {
  .vue__time-picker {
    input {
      outline: none;
    }
    .select-list {
      .active {
        background: $active_color !important;
      }
    }
  }
}
</style>

<script>
// import format from 'date-fns/format'
// import { addMinutes, isBefore, isAfter } from 'date-fns'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  components: {
    VueTimepicker
  },
  props: {
    label: {
      type: String,
      required: true
    },
    range: {
      type: Object,
      default: () => {
        return {
          start: '10:00',
          end: '18:00'
        }
      }
    }
  },
  data() {
    return {
      startTime: '10:00',
      endTime: '18:00',
      isInvalidRange: false
    }
  },
  created() {
    this.startTime = this.range.start
    this.endTime = this.range.end
  },
  watch: {
    range() {
      this.startTime = this.range.start
      this.endTime = this.range.end
    },
    startTime() {
      this.validateRange()
    },
    endTime() {
      this.validateRange()
    }
  },
  // created () {
  //   this.startTime = this.range.start
  //   this.endTime = this.range.end
  // },
  methods: {
    validateRange() {
      const startH = this.startTime.split(':')[0]
      const startM = this.startTime.split(':')[1]
      const endH = this.endTime.split(':')[0]
      const endM = this.endTime.split(':')[1]
      this.isInvalidRange =
        Number(startH) > Number(endH) || (startH === endH && Number(startM) >= Number(endM))

      this.$emit('updateTimeRange', {
        start: this.startTime,
        end: this.endTime
      })
    }
  }
}
</script>
