<template lang="pug">
  div.wrap-module-set-summary
    div.set-summary-content
      v-text-field(label="ミーティングのタイトル *"
        v-model="title"
        placeholder="ミーティングのタイトルを入力"
        color="black"
        clearable
        @click="titleIsManuallyEdited = true"
        @click:clear="onClear")
      v-textarea(label="ミーティングの概要"
        v-model="about"
        placeholder="ミーティングの概要を入力"
        color="black"
        rows="2"
        @click="aboutIsManuallyEdited = true")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-set-summary {
  width: 100%;
  // min-height: 100vh;
  // padding-top: $header_height;
  .set-summary-content {
    padding-top: 12px;
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  computed: {
    ...mapStateAuth(['uid'])
  },
  props: {
    meetingToTakeOver: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      title: 'やばいミーティング',
      about: '',
      titleIsManuallyEdited: false,
      aboutIsManuallyEdited: false
    }
  },
  watch: {
    title() {
      this.$emit('validateCurrentSettings')
    }
  },
  methods: {
    onClear() {
      console.log('onClear')
      this.$emit('setPreventNext', true)
    }
  }
}
</script>
