<template lang="pug">
div
  Header(:content="headerContent")
  div.wrap-set-group-to-meeting
    ModuleSetGroupToMeeting(:groups="groups"
      @setMemberByGroup="setMemberByGroup")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-set-group-to-meeting {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
import Header from '@/components/shared/Header.vue'
import ModuleSetGroupToMeeting from '@/components/module/ModuleSetGroupToMeeting'

export default {
  components: {
    Header,
    ModuleSetGroupToMeeting
  },
  data() {
    return {
      headerContent: {
        title: 'グループからメンバーを追加',
        left: {
          icon: 'mdi-chevron-left',
          action: this.onClose
        }
      },
      groups: [],
      preventNext: true
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created() {
    this.groups = await database.groupCollection(this.uid).all()
  },
  methods: {
    setMemberByGroup(member) {
      this.$emit('setMemberByGroup', member)
      this.$emit('closeModalPage')
    },
    onClose() {
      this.$emit('closeModalPage')
    }
  }
}
</script>
