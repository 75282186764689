<template lang="pug">
  div.wrap-item-date-range-picker
    span.label.fz14.block.mb4 候補日の範囲
    div(:class="{'is-invalid': isInvalidRange}").wrap-date-range.block
      div.f.fm
        div.wrap-start.wrap-day.px10.py4
          v-menu(offset-y)
            template(v-slot:activator="{ on }")
              div(v-on="on")
                span.fz18 {{startDayText}}
            template
              v-date-picker(v-model="startDayISOString" locale="jp")
        span.px4 ~
        div.wrap-end.wrap-day.px10.py4
          v-menu(offset-y)
            template(v-slot:activator="{ on }")
              div(v-on="on")
                span.fz18 {{endDayText}}
            template
              v-date-picker(v-model="endDayISOString" locale="jp")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.wrap-item-date-range-picker {
  border-bottom: $basic_border;
  .label {
    font-size: 12px;
    color: #777;
  }
  .wrap-date-range {
    .wrap-day {
      border: $basic_border;
      border-radius: 3px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.wrap-item-date-range-picker {
  .is-invalid {
    color: $dangerous_color;
  }
}
</style>

<script>
import format from 'date-fns/format'
import { startOfDay, endOfDay, isBefore } from 'date-fns'

export default {
  props: {
    dateRange: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      start: null,
      end: null,
      startDayISOString: null,
      endDayISOString: null,
      isInvalidRange: true
    }
  },
  computed: {
    startDayText() {
      return format(this.start, 'yyyy/MM/dd')
    },
    endDayText() {
      return format(this.end, 'yyyy/MM/dd')
    }
  },
  watch: {
    start() {
      this.validate()
    },
    end() {
      this.validate()
    },
    startDayISOString() {
      this.start = startOfDay(new Date(this.startDayISOString))
    },
    endDayISOString() {
      this.end = endOfDay(new Date(this.endDayISOString))
    }
  },
  created() {
    this.setDateRange()
  },
  methods: {
    setDateRange() {
      this.start = this.dateRange.start
      this.end = this.dateRange.end
      this.startDayISOString = this.start.toISOString().substr(0, 10)
      this.endDayISOString = this.end.toISOString().substr(0, 10)
    },
    validate() {
      this.isInvalidRange = !isBefore(this.start, this.end)
      this.$emit('updateDateRange', {
        start: this.start,
        end: this.end
      })
    }
  }
}
</script>
