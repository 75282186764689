<template lang="pug">
  Auth(:redirectWithLoginFailed="true")
    Header(:content="headerContent")
    div(v-if="uid").wrap-create-meeting.pb100
      div.mt12.mb12
        ModuleEditMember(ref="edit_member"
          @validateCurrentSettings="validateCurrentSettings")
      div.mb12
        ModuleSetAdjustedMeetingCondition(ref="set_condition"
          @validateCurrentSettings="validateCurrentSettings")
      ModuleSetSummary(ref="set_summary"
        @validateCurrentSettings="validateCurrentSettings")
      ModuleSelectMeetingRoomType(ref="select_meeting_room_type"
        @validateCurrentSettings="validateCurrentSettings")
      div(@click="onNext").wrap-invite-btn.f.fh
        div(:style="`background: ${btnBgColor};`").invite-btn-content.py12
          span.block.text-center.mr4 ミーティングを作成
    NowLoading(:show="showNowLoading")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-create-meeting {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .wrap-invite-btn {
    z-index: 1;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 12px;
    .invite-btn-content {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      cursor: pointer;
      // background: $active_color;
      border-radius: 28px;
      filter: $drop_shadow;
      span {
        color: $dark_primary_text_color;
      }
    }
  }
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header'
import NowLoading from '@/components/shared/NowLoading'
import ModuleSetSummary from '@/components/module/create-meeting/ModuleSetSummary'
import ModuleSetAdjustedMeetingCondition from '@/components/module/create-meeting/ModuleSetAdjustedMeetingCondition'
import ModuleEditMember from '@/components/module/create-meeting/ModuleEditMember3'
import ModuleSelectMeetingRoomType from '@/components/module/create-meeting/ModuleSelectMeetingRoomType'

export default {
  components: {
    Auth,
    Header,
    NowLoading,
    ModuleSetSummary,
    ModuleEditMember,
    ModuleSetAdjustedMeetingCondition,
    ModuleSelectMeetingRoomType
  },
  data() {
    return {
      headerContent: {
        title: '確定済みの予定を追加する',
        left: {
          icon: 'mdi-chevron-left',
          action: this.onBack
        }
      },
      preventNext: true,
      showNowLoading: false,
      btnBgColor: '#999'
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    preventNext() {
      this.btnBgColor = this.preventNext ? '#999' : '#4285f4'
    }
  },
  async mounted() {
    this.setSummary({
      title: '新規ミーティング',
      about: ''
    })
  },
  methods: {
    validateCurrentSettings() {
      const moduleSetSummary = this.$refs.set_summary
      const moduleEditMember = this.$refs.edit_member
      const moduleSetCondition = this.$refs.set_condition
      if (
        moduleEditMember.getMember()[0] &&
        moduleEditMember.allMemberIsValid() &&
        moduleSetSummary.title !== '' &&
        moduleSetSummary.title !== null &&
        moduleSetCondition.duration > 0 &&
        (!moduleSetCondition.$refs.dateRangePicker ||
          !moduleSetCondition.$refs.dateRangePicker.isInvalidRange) &&
        (!moduleSetCondition.$refs.timePicker ||
          !moduleSetCondition.$refs.timePicker.isInvalidRange)
      ) {
        this.preventNext = false
      } else {
        this.preventNext = true
      }
    },
    setSummary({ title, about }) {
      let moduleSetSummary = this.$refs.set_summary
      if (!moduleSetSummary.titleIsManuallyEdited) moduleSetSummary.title = title
      if (!moduleSetSummary.aboutIsManuallyEdited) moduleSetSummary.about = about
    },
    onBack() {
      this.$router.push('/calendar')
    },
    onNext() {
      if (this.preventNext) return
      this.createMtg()
    },
    async createMtg() {
      this.showNowLoading = true

      const user = await database.userCollection().findById(this.uid)

      const setSummary = this.$refs.set_summary
      const editMember = this.$refs.edit_member
      const setCondition = this.$refs.set_condition
      const selectMeetinRoomType = this.$refs.select_meeting_room_type

      if (!editMember.getMember()[0]) {
        alert('メンバーを入力してください')
        this.showNowLoading = false
        return
      }

      let meeting = {
        title: setSummary.title,
        about: setSummary.about,
        attendee: editMember.getMember().map((e) => {
          e.responsed = false
          return e
        }),
        // only for query of firestore array-contain
        attendeeEmail: editMember
          .getMember()
          .filter((e) => e.email && e.email !== '')
          .map((e) => e.email),
        duration: setCondition.duration,
        timeRange: setCondition.timeRange,
        // dateRange: setCondition.dateRange.value
        //   ? setCondition.dateRange.value
        //   : setCondition.dateRange,
        // dayOfWeek: setCondition.dayOfWeek.filter((e) => e.active).map((e) => e.day),
        startedAt: new Date(),
        adjustedAt: new Date(),
        adjustedDate: setCondition.getAdjustedDate(),
        status: 'adjusted',
        adjustmentPolicy: '04-no-adjustment',
        createdBy: this.uid,
        createdAt: new Date(),
        timeZone: 'Asia/Tokyo',
        sentNotiAfter24h: false,
        selectMeetinRoomType: selectMeetinRoomType.meetingURLType
      }
      meeting.attendeeEmail.push(user.email)

      await database.meetingCollection().add(meeting)

      this.showNowLoading = false

      this.$router.push('/calendar')
    },
    setPreventNext(preventNext) {
      this.preventNext = preventNext
    }
  }
}
</script>
