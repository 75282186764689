<template lang="pug">
  div(@click="fileredCandidates = []").wrap-module-create-meeting
    div(ref="moduleWrapper").create-meeting-content
      div.f.fm.flex-between
        span.label.fz14 参加者 *
        //- div(@click="showModalPageGroup = true").btn-group.f.fm.cursor-pointer
        //-   v-icon(size="18px" :color="pallete.$active_color").mr4 mdi-account-multiple
        //-   span.fz12 グループから参加者を設定

        ModalPage(:showModalPage="showModalPageGroup")
          SetGroupToMeeting(@closeModalPage="showModalPageGroup=false"
            @setMemberByGroup="setMemberByGroup")
      div(v-for="(form, formIndex) in forms").wrap-search
        v-menu(offset-y)
          template(v-slot:activator="{ on }")
            div(v-on="on").f.fm.flex-between.mt12
              div.mr6
                v-icon(v-if="'email' == retContactType(form.value)") mdi-email
                v-icon(v-if="'tel' == retContactType(form.value)") mdi-phone
                v-icon(v-if="'name' == retContactType(form.value)") mdi-account-circle
              // "名前 or email or 電話番号ハイフンなし"
              input(v-model="form.value"
                :ref="`form_${formIndex}`"
                :class="{'is-valid': form.isValid && !form.isDuplicated}"
                placeholder="名前 or email"
                @click="onSearchInput(formIndex)").px6.py4.mr4
              //- span(v-if="formIndex == forms.length - 1" @click.stop="onAdd" style="color: #4285f4;").fz14 追加
              //- span(v-else @click.stop="onRemove(formIndex)" style="color: #4285f4;").fz14 削除
              div.wrap-icon
                v-icon(@click.stop="onRemove(formIndex)" size="20px") mdi-close
                //- v-icon(v-if="formIndex == forms.length - 1"
                  @click.stop="onAdd" color="blue") mdi-plus
                //- v-icon(v-else @click.stop="onRemove(formIndex)" size="20px") mdi-close
          template
            div.wrap-member-candidates
              div(v-for="item in fileredCandidates"
                v-if="!item.selected && fileredCandidates[0]"
                @click.stop="onCandidateList(item, formIndex)").user-list.px8.py4
                div(v-if="item.email")
                  span.name.fz14.block {{(item.name != '') ? item.name: item.email.split('@')[0]}}
                  span.email.fz12.block {{item.email}}
                div(v-if="item.telNo")
                  span.name.fz14.block {{(item.name != '') ? item.name: item.telNo}}
                  span.tel-no.fz12.block {{item.telNo}}
      div.wrap-add-btn.f.flex-right.mt12
        div(@click.stop="onAdd").f.fm
          v-icon(color="#4285f4" size="22px").mr2 mdi-plus
          span.fz12 参加者入力欄を追加
      div.f.flex-right.mt4
        div(@click="showModalPageGroup = true").btn-group.f.fm.cursor-pointer
            v-icon(size="18px" :color="pallete.$active_color").mr4 mdi-account-multiple
            span.fz12 グループから参加者を設定
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-create-meeting {
  width: 100%;
  // min-height: 100vh;
  // padding-top: $header_height;
  .create-meeting-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .label {
      font-size: 12px;
      color: #777;
    }
    .btn-group {
      color: $active_color;
    }
    .wrap-inviter {
      color: $secondary_text_color;
    }
    .member-list {
      // border-top: solid $border_size rgba(0, 0, 0, 0.1);
      .email,
      .tel-no {
        color: $secondary_text_color;
      }
    }
    .wrap-add-btn {
      color: $active_color;
    }
  }
}
</style>

<style lang="scss">
@import '@/scss/_variables.scss';

.wrap-member-candidates {
  z-index: 10;
  top: 32px;
  // width: calc(100% - 30px - 6px);
  max-height: 320px;
  overflow: scroll;
  background: $primary_bg_color;
  border-radius: 3px;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
  .user-list {
    border-bottom: solid $border_size rgba(0, 0, 0, 0.1);
    .email {
      color: $secondary_text_color;
    }
  }
}

.wrap-module-create-meeting {
  .v-input__slot {
    margin: 0 !important;
  }
  .v-input__slot {
    min-height: 28px !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  .wrap-search {
    input {
      width: calc(100% - 20px);
      border: solid $border_color $border_size;
      border-radius: 3px;
      outline: none;
      color: $dangerous_color;
      &.is-valid {
        color: $primary_text_color;
      }
    }
    .wrap-icon {
      width: 20px;
    }
  }
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
import { getGoogleConnections } from '@/services/google'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import ModalPage from '@/components/shared/ModalPage'
import SetGroupToMeeting from '@/views/SetGroupToMeeting'

export default {
  components: {
    ModalPage,
    SetGroupToMeeting
  },
  computed: {
    ...mapStateAuth(['accessToken', 'uid'])
  },
  data() {
    return {
      searchText: '',
      token: null,
      memberFromGoogleContacts: [],
      memberInFirestoreContacts: [],
      candidates: [],
      fileredCandidates: [],
      addActive: false,
      user: null,
      forms: [{ value: '', isValid: false, isDuplicated: false }],
      currentFormIndex: null,
      showModalPageGroup: false
    }
  },
  watch: {
    forms: {
      handler() {
        if (this.currentFormIndex === null) return
        if (!this.forms[this.currentFormIndex]) return

        const targetValue = this.forms[this.currentFormIndex].value
        // console.log('targetValue', targetValue)
        this.fileredCandidates = this.candidates.filter((e) => {
          return (
            (e.name.includes(targetValue) || e.email.includes(targetValue)) &&
            targetValue !== e.email
          )
        })

        // // emailの正規表現
        // const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
        for (let i = 0; i < this.forms.length; i++) {
          // emailかどうか
          // this.forms[i].isValid = reg.test(this.forms[i].value)

          // 他に既に入力されたアドレスかどうか
          // const values = this.forms.map(e => e.value)
          //   .filter((e, index) => index !== i)
          // if (!values.includes(this.forms[i].value)) this.forms[i].isDuplicated = false
          // if (values.includes(this.forms[i].value) && !this.forms[i].isDuplicated) {
          //   this.forms[i].isValid = false
          //   this.forms[i].isDuplicated = true
          // }

          const values = this.forms.map((e) => e.value).filter((e, index) => index !== i)
          if (values.includes(this.forms[i].value) || this.forms[i] === '') {
            this.forms[i].isValid = false
          } else {
            this.forms[i].isValid = true
          }
        }
        this.$emit('validateCurrentSettings')
      },
      deep: true
    },
    searchText() {
      this.fileredCandidates = this.candidates.filter((e) => {
        return (
          e.name.includes(this.searchText) ||
          e.email.includes(this.searchText) ||
          e.telNo.includes(this.searchText)
        )
      })
      const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
      this.addActive = reg.test(this.searchText) && this.user.email !== this.searchText
    }
  },
  async created() {
    if (this.$route.params.defaultMembers) {
      const defaultMemberValues = decodeURIComponent(this.$route.params.defaultMembers).split(',')
      this.forms = defaultMemberValues.map((value) => {
        return { value, isValid: true, isDuplicated: false }
      })
    }
    // this.token = await database.userCollection().getGoogleToken(this.uid)

    this.loadContacts()
    // this.$emit('setPreventNext', true)

    this.user = await database.userCollection().findById(this.uid)
  },
  methods: {
    async loadContacts() {
      // サインアップ直後などで、accessTokenが生きている時はGoogle Contactを読み込む
      const { data: connections } = await getGoogleConnections(this.accessToken)
      this.addCandidatesFromGoogleContacts(connections)
      this.addCandidatesFromContactsInFirestore()
    },
    addCandidatesFromGoogleContacts(connections) {
      if (!connections) return

      this.memberFromGoogleContacts = connections
        .map((e) => {
          const email =
            e.emailAddresses && e.emailAddresses.length > 0 ? e.emailAddresses[0].value : undefined
          const name =
            e.names && e.names.length > 0 && e.names[0].displayName ? e.names[0].displayName : email
          return {
            email,
            telNo: '',
            name,
            selected: false
          }
        })
        .filter((v) => v.email)
      this.candidates = this.candidates.concat(this.memberFromGoogleContacts)
      this.removeDupulicatedCandidates()
    },
    async addCandidatesFromContactsInFirestore() {
      this.memberInFirestoreContacts = await database.userCollection().getAllContacts(this.uid)
      this.memberInFirestoreContacts = this.memberInFirestoreContacts.map((e) => {
        return {
          email: e.email ? e.email : '',
          telNo: e.telNo ? e.telNo : '',
          name: e.name,
          selected: false
        }
      })
      this.candidates = this.candidates.concat(this.memberInFirestoreContacts)
      this.removeDupulicatedCandidates()

      // firestoreのアドレスに登録されていなければ追加
      const emailsInFirestoreContacts = this.memberInFirestoreContacts.map((e) => e.email)
      // const emailsFromGoogleContacts = this.memberFromGoogleContacts.map((e) => e.email)
      for (let i = 0; i < this.memberFromGoogleContacts.length; i++) {
        if (!emailsInFirestoreContacts.includes(this.memberFromGoogleContacts[i].email)) {
          const newContact = {
            name: this.memberFromGoogleContacts[i].name,
            email: this.memberFromGoogleContacts[i].email,
            createdAt: new Date(),
            createdBy: this.uid
          }
          database.userCollection().addMemberToContact(this.uid, newContact)
        }
      }
    },
    removeDupulicatedCandidates() {
      // 重複したアドレスがあったら削除する
      const getArrayDeleteDuplication = (ary, key) => {
        let values = []
        return ary.filter((e) => {
          if (values.indexOf(e[key]) === -1) {
            values.push(e[key])
            return e
          }
        })
      }
      this.candidates = getArrayDeleteDuplication(this.candidates, 'email')
      this.fileredCandidates = this.candidates
      // 自分も入っていたらフィルター
      this.fileredCandidates = this.fileredCandidates.filter(
        (candidate) => candidate.email !== this.user.email
      )
    },
    onSearchInput(formIndex) {
      if (this.forms[formIndex].value === '') this.fileredCandidates = this.candidates
      this.currentFormIndex = formIndex
    },
    onCandidateList(candidate, formIndex) {
      this.forms[formIndex].value = candidate.email ? candidate.email : candidate.name
      this.forms[formIndex].email = candidate.email ? candidate.email : ''
      this.forms[formIndex].telNo = candidate.telNo ? candidate.telNo : ''
      this.forms[formIndex].isValid = true
      this.$refs.moduleWrapper.click()
    },
    onAdd() {
      this.forms.push({
        value: '',
        email: '',
        telNo: '',
        isValid: false,
        isDuplicated: false
      })
      this.currentFormIndex++
      this.$nextTick(() => {
        const focus = () => {
          this.$refs[`form_${this.currentFormIndex}`][0].focus()
          this.$refs[`form_${this.currentFormIndex}`][0].click()
        }
        setTimeout(focus, 400)
      })
    },
    onRemove(formIndex) {
      this.forms = this.forms.filter((e, index) => {
        return index !== formIndex
      })
      this.currentFormIndex--
    },
    getMember() {
      // const validEmails = this.forms
      //   .filter(e => e.isValid && !e.isDuplicated).map(e => e.value)

      // return validEmails.map(email => {
      //   let name
      //   const targetCandidata = this.candidates
      //     .filter((e) => e.email === email)[0]
      //   if (targetCandidata) {
      //     name = targetCandidata.name
      //     if (!name) name = this.searchText.split('@')[0]
      //   } else {
      //     name = email.split('@')[0]
      //   }

      //   return { email, name }
      // })

      return this.forms
        .filter((e) => e.isValid && !e.isDuplicated)
        .map((e) => {
          const invitationType = this.retContactType(e.value)
          let member = {
            name: e.value,
            email: e.email ? e.email : '',
            telNo: e.telNo ? e.telNo : ''
            // invitationType
          }
          // emailなら前半を参加者名にする
          if (invitationType === 'email' && !e.email) {
            member.name = e.value.split('@')[0]
            member.email = e.value
          }
          if (invitationType === 'tel') {
            member.telNo = e.value
          }
          return member
        })
        .filter((e) => e.name !== '')
    },
    allMemberIsValid() {
      const invaildEmails = this.forms.filter((e) => !e.isValid && e.value !== '')
      return invaildEmails[0] ? false : true
    },
    getNewContacts() {
      const member = this.getMember()
      const emailsInFirestoreContacts = this.memberInFirestoreContacts.map((e) => e.email)
      const telNosInFirestoreContacts = this.memberInFirestoreContacts.map((e) => e.telNo)
      return member.filter((e) => {
        if (e.email) {
          return !emailsInFirestoreContacts.includes(e.email)
        } else if (e.telNo) {
          return !telNosInFirestoreContacts.includes(e.telNo)
        } else {
          return false
        }
      })
    },
    retContactType(str) {
      const emailReg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
      if (emailReg.test(str)) return 'email'
      const telReg = /^(0{1}\d{9,10})$/
      if (telReg.test(str)) return 'tel'
      return 'name'
    },
    setMemberByGroup(member) {
      // forms: [{ value: '', isValid: false, isDuplicated: false }],
      this.forms = member.map((m) => {
        let value
        if (m.email) value = m.email
        else if (m.telNo) value = m.telNo
        else value = m.name
        return { value, isValid: true, isDuplicated: false }
      })
      this.$emit('validateCurrentSettings')
    },
    sleepByPromise(time) {
      return new Promise((resolve) => setTimeout(resolve, time))
    }
  }
}
</script>
