<template lang="pug">
  div.wrap-module-create-meeting
    div(v-show="!showSettings").create-meeting-content
      span.label.fz16.block.mb4 ミーティング日時
      div.f.fm.flex-between
        span.fz16 {{dateText}}
        span(@click="showSettings = true").toggle-settings.cursor-pointer.fz12 日時を編集
    div(v-show="showSettings").create-meeting-content
      span.label.fz14.block.mb4 日付
      div.wrap-day.px6.py4.mb18
        v-menu(offset-y)
          template(v-slot:activator="{ on }")
            div(v-on="on")
              span.fz18 {{dayText}}
          template
            v-date-picker(v-model="startDayISOString" locale="jp")
      div.wrap-time.mb18
        ItemTimePicker(v-if="timeRange"
          ref="timePicker"
          label="時間"
          :range="timeRange"
          @updateTimeRange="updateTimeRange")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-create-meeting {
  width: 100%;
  // min-height: 100vh;
  // padding-top: $header_height;
  .create-meeting-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .label {
      font-size: 12px;
      color: #777;
    }
    .toggle-settings {
      color: $active_color;
    }
    .wrap-day {
      width: 160px;
      border: $basic_border;
      border-radius: 3px;
    }
    .wrap-duration {
      width: 120px;
    }
  }
}
</style>

<style lang="scss">
.wrap-module-create-meeting {
  .v-input--selection-controls {
    margin: 0;
  }
}
</style>

<script>
import format from 'date-fns/format'
import { startOfDay, addMinutes, differenceInMinutes } from 'date-fns'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import ItemTimePicker from '@/components/item/ItemTimePicker2'
import ItemDateRangePicker from '@/components/item/ItemDateRangePicker'

export default {
  components: {
    ItemTimePicker,
    ItemDateRangePicker
  },
  computed: {
    ...mapStateAuth(['uid']),
    dayText() {
      return format(this.startDay, 'yyyy/MM/dd')
    },
    dateText() {
      return `${this.dayText} ${this.timeRange.start} ~ ${this.timeRange.end}`
    }
  },
  watch: {
    startDayISOString() {
      this.startDay = startOfDay(new Date(this.startDayISOString))
    },
    duration() {
      this.validate()
    },
    timeRange() {
      this.validate()

      const startTime = new Date(
        `${this.startDayISOString.replaceAll('-', '/')} ${this.timeRange.start}`
      )
      const endTime = new Date(
        `${this.startDayISOString.replaceAll('-', '/')} ${this.timeRange.end}`
      )
      this.duration = differenceInMinutes(endTime, startTime)
    }
  },
  data() {
    return {
      showSettings: false,
      generateSlotsWith: 'google',
      duration: 60,
      startDate: new Date(),
      startDay: null,
      startDayISOString: null,
      timeRange: null
      // timeRange: {
      //   start: '10:00',
      //   end: '17:00'
      // }
    }
  },
  created() {
    this.duration = Number(this.$route.params.duration)
    this.startDate = new Date(this.$route.params.startDate.replaceAll('-', '/'))
    this.startDay = startOfDay(this.startDate)
    this.startDayISOString = this.startDate.toISOString().substr(0, 10)
    this.timeRange = {
      start: format(this.startDate, 'HH:mm'),
      end: format(addMinutes(this.startDate, this.duration), 'HH:mm')
    }
  },
  methods: {
    updateTimeRange(timeRange) {
      this.timeRange = timeRange
    },
    validate() {
      this.$emit('validateCurrentSettings')
    },
    getAdjustedDate() {
      // const start = new Date(`${this.startDay}`)
      const startTime = this.timeRange.start
      const start = new Date(`${this.startDayISOString.replaceAll('-', '/')} ${startTime}`)
      const end = addMinutes(start, this.duration)
      return { start, end }
    }
  }
}
</script>
