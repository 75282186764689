<template lang="pug">
  div(@click="setMemberByGroup").wrap-item-group-card.py8
    div.card-content
      div.f.fm.flex-between.mb4
        span.block.fz16 {{group.name}}
        span.btn-select.block.fz14.cursor-pointer 選択
      div.wrap-member
        div(v-for="member in group.member").member.f.mb4
          //- div.mr6
            v-icon(v-if="'email' == retContactType(member.name)") mdi-email
            v-icon(v-if="'tel' == retContactType(member.name)") mdi-phone
            v-icon(v-if="'name' == retContactType(member.name)") mdi-account-circle
          div.mr6
            v-icon(v-if="member.email") mdi-email
            v-icon(v-else-if="member.telNo") mdi-phone
            v-icon(v-else) mdi-account-circle
          div.pt2
            span.block.fz14 {{member.name}}
            span(v-if="member.email").contact.fz12 {{member.email}}
            span(v-if="member.telNo").contact.fz12 {{member.telNo}}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-item-group-card {
  border-bottom: $basic_border;
  .card-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .btn-select {
      color: $active_color;
    }
    .member {
      .contact {
        color: $secondary_text_color;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  methods: {
    setMemberByGroup () {
      this.$emit('setMemberByGroup', this.group.member)
    },
    // retContactType(str) {
    //   const emailReg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
    //   if (emailReg.test(str)) return 'email'
    //   const telReg = /^(0{1}\d{9,10})$/
    //   if (telReg.test(str)) return 'tel'
    //   return 'name'
    // }
  }
}
</script>
